import { Injectable } from '@angular/core'
import { ACLService } from '@delon/acl'
import { Menu, MenuService as MenuSrv, _HttpClient } from '@delon/theme'
import { ArrayService } from '@delon/util'
import { DEMO_MENUS } from '@public/pages/demo-pages'
import { HttpMenuService, MenuModel } from '@public/providers/http/http-menu.service'
import { AsyncMenu } from '@scaffold/core/menu/async-menu.service'
import { treeFormat } from '@scaffold/methods'
import { difference, uniq } from 'lodash'
import { map, tap } from 'rxjs/operators'
import { environment } from '../environments/environment'

@Injectable()
export class AsyncMenuService extends AsyncMenu {

  constructor(
    public menuService: MenuSrv,
    protected http: _HttpClient,
    protected httpMenuService: HttpMenuService,
    protected arrayService: ArrayService,
    protected aclService: ACLService,
  ) {
    super(menuService, environment, http)
  }

  sync() {
    return this.pullMenusTree()
  }

  /** 拉取菜单树 */
  pullMenusTree() {
    const appId = this.env.APPID
    return this.httpMenuService.getMenusByCurrenUser({ appId, _quiet_: 1 })
      .pipe(
        map(({ response }) => response),

        /** 菜单排序 */
        map((list: MenuModel[]) => list.sort((a, b) => a.sortOrder - b.sortOrder)),

        /** 设置权限 */
        tap(list => this.initAcl(list)),

        map((list: MenuModel[]) => list.map(item => ({
          ...item,
          i18n: item.name,
          key: item.id,
          link: item.url,
          icon: { type: 'icon', theme: 'fill', value: item.icon },
        }))),

        /** array to tree */
        map((list) => treeFormat(list, { idKey: 'id', parentKey: 'parentId' })),

        /** visitTree */
        map(menusTree => menusTree?.[0]?.children),

        /** 添加 demo menus */
        tap((children: Menu[]) => {
          const menus: Menu[] = [{ text: '菜单', hideInBreadcrumb: true, group: true, children, }]
          super.setMenus(environment.production ? menus : [...menus, ...DEMO_MENUS])
        })
      )
  }

  private initAcl(list: MenuModel[]) {
    this.aclService.setFull(false)
    let ability = list.map(({ url }) => url)
    ability = difference(ability, ['/', '', '#'])
    ability = uniq(ability)
    this.aclService.set({ ability })
  }
}
