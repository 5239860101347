import { Routes } from '@angular/router'
import { SimpleGuard } from '@delon/auth'
import { ACLGuard } from '@jianguan/shared/acl-guard.guard'
import { LayoutComponent } from '@jianguan/shared/layout'
import { Exception404Component } from '@public/pages/exception/404.component'
import { SCAFFOLD_PAGES } from '@public/pages/page-routes'
import { CHAOSHI_ROUTES } from './chaoshi-routes'
import { CX_ROUTES } from './cx-routes'
import { JG_ROUTES } from './jg-routes'
import { SYSTEM_ROUTES } from './system.routes'

const OTHERS: Routes = [

  // 演示页面
  { path: 'demo', loadChildren: () => import('@public/pages/demo-pages/routes.module').then(t => t.DemoRoutesModule) }
]

export const PAGES_ROUTES: Routes = [
  ...SCAFFOLD_PAGES,
  {
    path: 'print-zs',
    loadChildren: () => import('./print-zs/print-zs.module').then(t => t.PrintZsModule)
  },
  {
    path: 'print-tk',
    loadChildren: () => import('./print-tk/print-tk.module').then(t => t.PrintTkModule)
  },
  {
    path: 'print-xjtj',
    loadChildren: () => import('./print-xjtj/print-xjtj.module').then(t => t.PrintXjtjModule)
  },
  {
    path: 'print-zonghe',
    loadChildren: () => import('./print-zonghe/print-zonghe.module').then(t => t.PrintZongheModule)
  },
  {
    path: 'print-checking',
    loadChildren: () => import('./print-checking/print-checking.module').then(t => t.PrintCheckingModule)
  },
  {
    path: 'print-transfer',
    loadChildren: () => import('./print-transfer/print-transfer.module').then(t => t.PrintTransferModule)
  },
  {
    path: 'print-voucher',
    loadChildren: () => import('./print-voucher/print-voucher.module').then(t => t.PrintVoucherModule)
  },
  {
    path: 'print-balance',
    loadChildren: () => import('./print-balance/print-balance.module').then(t => t.PrintBalanceModule)
  },
  {
    path: 'print-jiashu',
    loadChildren: () => import('./print-jiashu/print-jiashu.module').then(t => t.PrintJiashuModule)
  },
  // 家属退货打印
  {
    path: 'print-onlyOrder',
    loadChildren: () => import('./sp-chaoshi-dingdan-salesReturn/print-onlyOrder/print-onlyOrder.module').then(t => t.PrintOnlyOrderModule)
  },
  {
    path: 'print-person',
    loadChildren: () => import('./print-person/print-person.module').then(t => t.PrintPersonModule)
  },
  {
    path: 'print-bfinance-detail',
    loadChildren: () => import('./print-bfinance-detail/print-bfinance-detail.module').then(t => t.PrintBfinanceDetailModule)
  },
  {
    path: 'print-bfinance-total',
    loadChildren: () => import('./print-bfinance-total/print-bfinance-total.module').then(t => t.PrintBfinanceTotalModule)
  },
  {
    path: 'print-dingdan',
    loadChildren: () => import('./print-dingdan/print-dingdan.module').then(t => t.PrintDingdanModule)
  },
  {
    path: 'print-financial-running-water',
    loadChildren: () => import('./print-financial-running-water/print-financial-running-water.module')
      .then(t => t.PrintFinancialRunningWaterModule)
  },
  {
    path: 'print-caiwu-suo',
    loadChildren: () => import('./print-caiwu-suo/print-caiwu-suo.module').then(t => t.PrintCaiwuSuoModule)
  },
  {
    path: 'print-drugs-indent',
    loadChildren: () => import('./print-drugs-indent/print-drugs-indent.module').then(t => t.PrintDrugsIndentModule)
  },
  {
    path: 'print-accounting-entry-statistics',
    loadChildren: () => import('./print-accounting-entry-statistics/print-accounting-entry-statistics.module').
      then(t => t.PrintAccountingEntryStatisticsModule)
  },
  {
    path: 'print-history-person-out-money',
    loadChildren: () => import('./print-history-person-out-money/print-history-person-out-money.module')
      .then(t => t.PrintHistoryPersonOutMoneyModule)
  },
  {
    path: 'print-taolao',
    loadChildren: () => import('./print-taolao/print-taolao.module').then(t => t.PrintTaolaoModule)
  },
  {
    path: 'print-financial-running-water-zhidui-export',
    loadChildren: () => import('./print-financial-running-water-zhidui-export/print-financial-running-water-zhidui-export.module')
      .then(t => t.PrintFinancialRunningWaterZhiduiExportModule)
  },
  {
    path: 'print-financial-running-water-zhidui-detail',
    loadChildren: () => import('./print-financial-running-water-zhidui-detail/print-financial-running-water-zhidui-detail.module')
      .then(t => t.PrintFinancialRunningWaterZhiduiDetailModule)
  },
  {
    path: 'print-report',
    loadChildren: () => import('./print-report/print-report.module').then(t => t.PrintReportModule)
  },
  {
    path: '',
    canActivate: [SimpleGuard], canActivateChild: [SimpleGuard, ACLGuard],
    children: [
      {
        path: '', component: LayoutComponent,
        children: [

          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          ...CX_ROUTES,
          ...JG_ROUTES,
          ...CHAOSHI_ROUTES,
          ...SYSTEM_ROUTES,
          {
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
          },

          /** 业务子模块 */
          ...OTHERS,
          { path: 'exception', loadChildren: () => import('@public/pages/exception').then(t => t.ExceptionModule) },
          { path: '403', redirectTo: '/exception/403' },
          { path: '404', redirectTo: '/exception/404' },
          { path: '500', redirectTo: '/exception/500' },
          { path: '**', component: Exception404Component, data: { title: '页面找不到' } }
        ]
      },
    ]
  },

]
