import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DelonFormModule } from '@delon/form'
import { PromptComponent } from './prompt.component'

@NgModule({
  declarations: [PromptComponent],
  imports: [
    CommonModule,
    DelonFormModule
  ]
})
export class PromptModule {}
