import { Inject, Injectable } from '@angular/core'
import { ALAIN_I18N_TOKEN, _HttpClient } from '@delon/theme'
import { TranslateService } from '@ngx-translate/core'
import { AsyncI18n } from '@scaffold/core/startup/async/async-i18n'
import { asyncCatch } from '@scaffold/methods'
import { I18NService } from '@scaffold/providers/i18n'

@Injectable()
export class AsyncI18nService extends AsyncI18n {

  constructor(
    @Inject(ALAIN_I18N_TOKEN) i18n: I18NService,
    httpClient: _HttpClient,
    translate: TranslateService,
  ) {
    super(i18n, httpClient, translate)
  }

  async sync(): Promise<any> {
    const app$ = this.httpClient.get(`assets/i18n/app.i18n.json`, { _quiet_: 1, _local_: 1 }).toPromise()
    const jianguan$ = this.httpClient.get(`assets/data/jianguan.i18n.json`, { _quiet_: 1, _local_: 1 }).toPromise()
    const [[appI18n, jianguanI18n]] = await asyncCatch(Promise.all([app$, jianguan$]))
    const i18nList = { ...appI18n, ...jianguanI18n }

    this.translate.setTranslation(this.i18n.defaultLang, i18nList)
    this.translate.setDefaultLang(this.i18n.defaultLang)
    return i18nList
  }
}
