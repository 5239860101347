import { ChangeDetectionStrategy, Component } from '@angular/core'
import { LayoutProHeaderComponent } from '@scaffold/layout/components/header/header.component'

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  host: {
    '[class.ant-layout-header]': 'true',
    '[class.alain-pro__header-fixed]': 'pro.fixedHeader',
    '[class.alain-pro__header-hide]': 'hideHeader',
    '[style.padding.px]': '0',
    '[style.height.px]': '50',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHeaderComponent extends LayoutProHeaderComponent {
}
