import { Injector } from '@angular/core'
import { SFSchema } from '@delon/form'
import { sfDefault } from '@public/methods/sf-util'
import { InputComponent } from '@public/pages/demo-pages/debug/input'
import { NzModalService } from 'ng-zorro-antd/modal'

const DEFAULT_SF: SFSchema = {
  properties: {
    text: { title: '填写内容', ...sfDefault() }
  }
}

export function Prompt(title = '请填写内容', schema: SFSchema = DEFAULT_SF) {
  return (target: { injector: Injector }, name, descriptor) => {
    descriptor.value = function() {
      return new Promise(
        (resolve, reject) => {
          const modal: NzModalService = this.injector.get(NzModalService)
          modal.create({
            nzTitle: title,
            nzContent: InputComponent,
            nzComponentParams: { schema },
            nzOnOk: (t: InputComponent) => resolve(t.value),
            nzOnCancel: () => reject()
          })
        })
    }
  }
}
