import { Location } from '@angular/common'
import { Component } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'exception-404',
  template: `
    <exception class="bg-white" type="404" style="min-height: 500px; height: calc(100vh - 230px);">
      <h3 class="exception__cont-desc">请求地址 <span class="text-uppercase">{{path}}</span> 没有找到。</h3>
      <p>
        The requested URL <span class="text-primary">{{path}}</span> was not found on this server.
        That’s all we know.
      </p>
    </exception>
  `,
})
export class Exception404Component {
  path: string

  constructor(modalSrv: NzModalService, location: Location) {
    modalSrv.closeAll()
    this.path = location.path()
  }
}
