import { Routes } from '@angular/router'

export const CX_ROUTES: Routes = [
  /** 查询、综合 */
  { path: 'integrate', redirectTo: '/cx-zonghe' },
  {
    path: 'cx-zonghe',
    loadChildren: () => import('./cx-zonghe/cx-zonghe.module').then(m => m.CxZongheModule)
  },
  {
    path: 'cx-lock',
    loadChildren: () => import('./cx-lock/cx-lock.module').then(m => m.CxLockModule)
  },
  {
    path: 'cx-videoLock',
    loadChildren: () => import('./cx-videoLock/cx-videoLock.module').then(m => m.CxVideoLockModule)
  },
  // 每日余额查询
  {
    path: 'cx-balance',
    loadChildren: () => import('./cx-balance/cx-balance.module').then(m => m.CxBalanceModule)
  },
  {
    path: 'cx-zonghe-fixed',
    loadChildren: () => import('./cx-zonghe-fixed/cx-zonghe.module').then(m => m.CxZongheModule)
  },

  /** 查询、财务 / 购物记录 */
  { path: 'shopping-log', redirectTo: '/cx-gouwu-log' },
  {
    path: 'cx-gouwu-log',
    loadChildren: () => import('./cx-gouwu-log/gouwu-log.module').then(m => m.GouwuLogModule)
  },

  /** 查询、家属 */
  { path: 'search-family', redirectTo: '/cx-jiashu-bangding' },
  {
    path: 'cx-jiashu-bangding',
    loadChildren: () => import('./cx-jiashu-bangding/cx-jiashu-bangding.module').then(m => m.CxJiashuBangdingModule)
  },

  /** 查询、财务 / 退帐流水 */
  { path: 'illicit', redirectTo: '/cx-tuizhang' },
  {
    path: 'cx-tuizhang',
    loadChildren: () => import('./cx-tuizhang/cx-tuizhang.module').then(m => m.CxTuizhangModule)
  },

  /** 查询订单 */
  {
    path: 'cx-order',
    loadChildren: () => import('./cx-order/cx-order.module').then(m => m.CxOrderModule)
  },
  {
    path: 'prisoner',
    loadChildren: () => import('./cx-zyry-detail/zyry-detail.module').then(m => m.ZyryDetailModule)
  },

  /** 管教 / 综合业务 */
  {
    path: 'zonghe',
    loadChildren: () => import('./zonghe/cx-zonghe.module').then(m => m.CxZongheModule)
  },

  {
    path: 'cx-zhuanzhang',
    loadChildren: () => import('./cx-zhuanzhang/cx-zhuanzhang.module').then(m => m.CxZhuanzhangModule)
  },
  /**  / 购物权限申请记录 */
  {
    path: 'cx-account-purchase-limit',
    loadChildren: () => import('./cx-account-purchase-limit/cx-account-purchase-limit.module')
      .then(m => m.CxAccountPurchaseLimitModule)
  },

  { path: 'cx-toulao', loadChildren: () => import('./cx-toulao/cx-toulao.module').then(t => t.CxToulaoModule) },

  /**  / 管教 / 二维码日志记录 */
  {
    path: 'cx-withdrawal-log',
    loadChildren: () => import('./cx-withdrawal-log/cx-withdrawal-log.module').then(m => m.CxWithdrawalLogModule)
  },
  {
    path: 'cx-checking',
    loadChildren: () => import('./cx-checking/cx-checking.module').then(m => m.CxCheckingModule)
  },
  {
    path: 'cx-transfer',
    loadChildren: () => import('./cx-transfer/cx-transfer.module').then(m => m.CxTransferModule)
  },
  /**  / 查询 / 按未发货订单查询及导出 */
  {
    path: 'cx-unsend-order',
    loadChildren: () => import('./cx-unsend-order/cx-unsend-order.module').then(m => m.CxUnsendOrderModule)
  },

  /**  / 查询 / 手工帐 */
  {
    path: 'cx-manual',
    loadChildren: () => import('./cx-manual/cx-manual.module').then(m => m.CxManualModule)
  },

  {
    path: 'cx-manual-fail-blank',
    loadChildren: () => import('./cx-manual-fail-blank/cx-manual-fail-blank.module').then(m => m.CxManualFailBlankModule)
  },
  /** 财务流水 */
  {
    path: 'bl-financial-running-water-export',
    loadChildren: () => import('./bl-financial-running-water-export/bl-financial-running-water-export.module')
      .then(m => m.BlFinancialRunningWaterExportModule)
  },
  /** 支队个人信息查询 */
  {
    path: 'cx-zhidui-person-info',
    loadChildren: () => import('./cx-zhidui-person-info/cx-zhidui-person-info.module').then(t => t.CxZhiduiPersonInfoModule)
  },
  /** 财务个人信息查询 */
  {
    path: 'cx-caiwu-person-info',
    loadChildren: () => import('./cx-caiwu-person-info/cx-caiwu-person-info.module').then(t => t.CxCaiwuPersonInfoModule)
  },
  /** 支出收入统计查询 */
  {
    path: 'cx-accounting-entry-statistics',
    loadChildren: () => import('./cx-accounting-entry-statistics/cx-accounting-entry-statistics.module')
      .then(t => t.CxAccountingEntryStatisticsModule)
  },
  /** 财务流水（支队）  财务汇集流水 */
  {
    path: 'bl-financial-running-water-zhidui-export',
    loadChildren: () => import('./bl-financial-running-water-zhidui-export/bl-financial-running-water-zhidui-export.module')
      .then(m => m.BlFinancialRunningWaterZhiduiExportModule)
  },
  /** 历史人员出所资金查询接口 */
  {
    path: 'cx-history-person-out-money',
    loadChildren: () => import('./cx-history-person-out-money/cx-history-person-out-money.module')
      .then(t => t.CxHistoryPersonOutMoneyModule)
  },
  /** 监狱投送金额查询 */
  {
    path: 'cx-prison-projection-money',
    loadChildren: () => import('./cx-prison-projection-money/cx-prison-projection-money.module')
      .then(t => t.CxPrisonProjectionMoneyModule)
  }
]
