import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ReuseTabModule } from '@delon/abc'
import { YQHeaderModule } from '@jianguan/shared/layout/header'
import { LayoutService } from '@jianguan/shared/layout/layout.service'
import { YQLogoModule } from '@jianguan/shared/layout/logo'
import { FooterModule } from '@scaffold/layout/components/footer'
import { BrandService } from '@scaffold/layout/layout.service'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { LayoutComponent } from './layout.component'
import { YQMenuModule } from './menu'

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    NzLayoutModule,
    YQMenuModule,
    NzDrawerModule,
    YQHeaderModule,
    NzSpinModule,
    RouterModule,
    YQLogoModule,
    FooterModule,
    ReuseTabModule,
  ],
  providers: [{ provide: BrandService, useClass: LayoutService }]
})
export class LayoutModule {}
