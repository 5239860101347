import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LayoutLogoComponent } from './logo.component'

@NgModule({
  declarations: [LayoutLogoComponent],
  exports: [LayoutLogoComponent],
  imports: [
    CommonModule,
    RouterModule,
  ],
})
export class YQLogoModule {}
