import { Routes } from '@angular/router'

export const JG_ROUTES: Routes = [
  /** 审批 / 转帐 */
  { path: 'transfer', redirectTo: '/sp-zhuanzhang' },
  {
    path: 'sp-zhuanzhang',
    loadChildren: () => import('./sp-zhuanzhang/sp-zhuanzhang.module').then(m => m.SpZhuanzhangModule)
  },

  /** 审批 / 在押人员购物审批 */
  {
    path: 'sp-gouwu-sld-old',
    loadChildren: () => import('./sp-gouwu-sld-old/sp-zyry-zhangdan.module').then(m => m.SpZyryZhangdanModule)
  },

  /** 审批 / 在押人员购物审批 */
  {
    path: 'sp-gouwu-sld',
    loadChildren: () => import('./sp-gouwu-sld/sp-gouwu-sld.module').then(m => m.SpGouwuSldModule)
  },

  /** 审批 / 代购 */
  {
    path: 'sp-daigou-sld',
    loadChildren: () => import('./sp-daigou-sld/sp-daigou-sld.module').then(m => m.SpDaigouSldModule)
  },

  /** 审批 / 所领导 / 商品限制审批 */
  {
    path: 'limit',
    loadChildren: () => import('./sp-gouwu-limit/sp-gouwu-limit.module').then(m => m.SpGouwuLimitModule)
  },

  /** 审批 / 所领导 / 商品解除限制审批 */
  {
    path: 'unlimit',
    loadChildren: () => import('./sp-sld-goods-unlimit/sp-sld-goods-unlimit.module').then(m => m.SpSldGoodsUnlimitModule)
  },

  /** 审批 / 限额审批 */
  {
    path: 'sp-sld-limit-amount',
    loadChildren: () => import('./sp-sld-limit-amount/sp-sld-limit-amount.module').then(m => m.SpSldLimitAmountModule)
  },

  /** 审批 / 购物权限限制审批 */
  {
    path: 'sp-sld-account-purchase-limit',
    loadChildren: () => import('./sp-sld-account-purchase-limit/sp-sld-account-purchase-limit.module')
      .then(m => m.SpSldAccountPurchaseLimitModule)
  },

  /** 审批 / 购物权限解除审批 */
  {
    path: 'sp-sld-account-purchase-unlimit',
    loadChildren: () => import('./sp-sld-account-purchase-unlimit/sp-sld-account-purchase-unlimit.module')
      .then(m => m.SpSldAccountPurchaseUnlimitModule)
  },

  /** 审批 / 商品上架审批 */
  {
    path: 'sp-goods-up',
    loadChildren: () => import('./sp-shangping-shangjiaa/sp-shangping-shangjiaa.module').then(m => m.SpShangpingShangjiaaModule)
  },

  /** 审批 / 退帐流水 */
  {
    path: 'sp-tuizhang',
    loadChildren: () => import('./sp-tuizhang/sp-tuizhang.module').then(m => m.SpTuizhangModule)
  },

  /** 管教 / 退赃 */
  {
    path: 'tuizang',
    loadChildren: () => import('./bl-tuizang/bl-tuizang.module').then(m => m.BlTuizangModule)
  },

  /** 管教 / 退赃 */
  {
    path: 'zhuanzhang',
    loadChildren: () => import('./bl-zhuanzhang/bl-zhuanzhang.module').then(m => m.BlZhuanzhangModule)
  },


  /** 管教 / 记事本 */
  {
    path: 'bookKeeping',
    loadChildren: () => import('./bl-bookKeeping/bl-bookKeeping.module').then(m => m.BlBookKeepingModule)
  },


  /** 管教 / 视频控制 */
  {
    path: 'videoControl',
    loadChildren: () => import('./videoControl/videoControl.module').then(m => m.VideoControlModule)
  },


  /** 管教 / 赔偿 */
  {
    path: 'bl-compensation',
    loadChildren: () => import('./bl-compensation/bl-compensation.module').then(m => m.BlCompensationModule)
  },

  /** 审批 / 在押人员购物审批 */
  {
    path: 'sp-gouwu',
    loadChildren: () => import('./sp-gouwu-guanjiao/sp-gouwu-guanjiao.module').then(m => m.SpGouwuGuanjiaoModule)
  },

  /** 审批 / 在押人员购物审批 家属 */
  {
    path: 'sp-gouwu-jiashu',
    loadChildren: () => import('./sp-gouwu-guanjiao-jiashu/sp-gouwu-guanjiao.module').then(m => m.SpGouwuGuanjiaoModule)
  },

  /** 审批 / 家属绑定 */
  {
    path: 'sp-jiashu-bingding',
    loadChildren: () => import('./sp-jiashu-bingding/sp-jiashu-bingding.module').then(m => m.SpJiashuBingdingModule)
  },

  /** 审批 / 赔偿转账 */
  {
    path: 'sp-compensation',
    loadChildren: () => import('./sp-compensation/sp-compensation.module').then(m => m.SpCompensationModule)
  },

  /** 管教 / 办理 / 购物权限限制办理 */
  {
    path: 'bl-account-purchase-limit',
    loadChildren: () => import('./bl-account-purchase-limit/bl-account-purchase-limit.module')
      .then(m => m.BlAccountPurchaseLimitModule)
  },

  /** 管教 / 办理 / 购物权限解除限制办理 */
  {
    path: 'bl-account-purchase-unlimit',
    loadChildren: () => import('./bl-account-purchase-unlimit/bl-account-purchase-unlimit.module')
      .then(m => m.BlAccountPurchaseUnlimitModule)
  },

  /** 管教 / 办理 / 限额 */
  {
    path: 'bl-limit-amount',
    loadChildren: () => import('./bl-limit-amount/bl-limit-amount.module')
      .then(m => m.BlLimitAmountModule)
  },

  /** 管教 / 办理 / 商品限制 */
  {
    path: 'bl-goods-limit',
    loadChildren: () => import('./bl-goods-limit/bl-goods-limit.module')
      .then(m => m.BlGoodsLimitModule)
  },

  /** 管教 / 办理 / 自购物品申请 */
  {
    path: 'bl-indent',
    loadChildren: () => import('./bl-indent/bl-indent.module')
      .then(m => m.BlIndentModule)
  },

  /** 管教 / 办理 / 自购药品申请 */
  {
    path: 'bl-drugs-indent',
    loadChildren: () => import('./bl-drugs-indent/bl-drugs-indent.module')
      .then(m => m.BlDrugsIndentModule)
  },

  /** 管教 / 办理 / 取款凭证办理 */
  {
    path: 'bl-withdrawal',
    loadChildren: () => import('./bl-withdrawal/bl-withdrawal.module')
      .then(m => m.BlWithdrawalModule)
  },

  /** 审批 / 购物限制预设 / 财务 */
  {
    path: 'bl-gouwu-xianzhi-yisheng',
    loadChildren: () => import('./bl-gouwu-xianzhi-yisheng/bl-gouwu-xianzhi.module').then(m => m.BlGouwuXianzhiModule)
  },

  /** 审批 / 代购  / 医生 */
  {
    path: 'sp-daigou-yisheng',
    loadChildren: () => import('./sp-daigou-yisheng/sp-daigou.module').then(m => m.SpDaigouModule)
  },
  /** 财务 / 转账申请审批 */
  {
    path: 'sp-caiwu-transfer',
    loadChildren: () => import('./sp-caiwu-transfer/sp-caiwu-transfer.module').then(m => m.SpCaiwuTransferModule)
  },
  /**  在押人员购物帐单审批 */
  {
    path: 'sp-gouwu-caiwu-suonei',
    loadChildren: () => import('./sp-gouwu-caiwu-suonei/sp-gouwu-caiwu-suonei.module').then(m => m.SpGouwuCaiwuSuoneiModule)
  },

  /** 办理 / 投牢 */
  { path: 'bl-toulao', loadChildren: () => import('./bl-toulao/bl-toulao.module').then(m => m.BlToulaoModule) },

  /** 办理 / 配送前确认 */
  {
    path: 'confirm', redirectTo: '/sp-zyry-dingdan'
  },
  {
    path: 'sp-zyry-dingdan',
    loadChildren: () => import('./sp-zyry-dingdan/sp-zyry-dingdan.module').then(m => m.SpZyryDingdanModule)
  },

  /** 财务 / 在押人员购物帐单审批 */
  { path: 'financial', redirectTo: '/sp-zyry-zhangdan' },
  {
    path: 'sp-zyry-zhangdan',
    loadChildren: () => import('./sp-gouwu-sld-old/sp-zyry-zhangdan.module').then(m => m.SpZyryZhangdanModule)
  },

  { path: 'audit', redirectTo: '/cx-zhang-wu-liu-shui' },
  {
    path: 'cx-zhang-wu-liu-shui',
    loadChildren: () => import('./cx-zhang-wu-liu-shui/cx-zhang-wu-liu-shui.module').then(m => m.CxZhangWuLiuShuiModule)
  },

  /** 财务 / 超市绑定 */
  { path: 'marketbind', redirectTo: '/bl-chaoshi-bind' },
  {
    path: 'bl-chaoshi-bind',
    loadChildren: () => import('./bl-chaoshi-bind/bl-chaoshi-bind.module').then(m => m.BlChaoshiBindModule)
  },

  /** 财务 / 代购申请确认 */
  {
    path: 'sp-caiwu-indent-apply',
    loadChildren: () => import('./sp-caiwu-indent-apply/sp-caiwu-indent-apply.module').then(m => m.SpCaiwuIndentApplyModule)
  },

  /** 财务 / 代购回执确认 */
  {
    path: 'sp-caiwu-indent-receipt',
    loadChildren: () => import('./sp-caiwu-indent-receipt/sp-caiwu-indent-receipt.module').then(m => m.SpCaiwuIndentReceiptModule)
  },

  /** 财务 / 退赃确认 */
  {
    path: 'sp-caiwu-tuizang',
    loadChildren: () => import('./sp-caiwu-tuizang/sp-caiwu-tuizang.module').then(m => m.SpCaiwuTuizangModule)
  },

  /** 财务 / 赔偿转账确认 */
  {
    path: 'sp-caiwu-compensation',
    loadChildren: () => import('./sp-caiwu-compensation/sp-caiwu-compensation.module').then(m => m.SpCaiwuCompensationModule)
  },

  {
    path: 'sp-gouwu-caiwu-suonei',
    loadChildren: () => import('./sp-gouwu-caiwu-suonei/sp-gouwu-caiwu-suonei.module').then(t => t.SpGouwuCaiwuSuoneiModule)
  },

  {
    path: 'sp-gouwu-caiwu-zhidui',
    loadChildren: () => import('./sp-gouwu-caiwu-zhidui/sp-gouwu-caiwu-zhidui.module').then(t => t.SpGouwuCaiwuZhiduiModule)
  },

  {
    path: 'sp-gouwu-caiwu-peisongqian-zhidui',
    loadChildren: () => import('./sp-gouwu-caiwu-peisongqian-zhidui/sp-gouwu-caiwu-peisongqian-zhidui.module')
      .then(t => t.SpGouwuCaiwuPeisongqianZhiduiModule)
  },

  {
    path: 'sp-gouwu-caiwu-peisongqian',
    loadChildren: () => import('./sp-gouwu-caiwu-peisongqian/sp-gouwu-caiwu-peisongqian.module').then(t => t.SpGouwuCaiwuPeisongqianModule)
  },

  /** 所领导 / 审批超市绑定  */

  {
    path: 'sp-chaoshi-bind',
    loadChildren: () => import('./sp-chaoshi-bind/sp-chaoshi-bind.module').then(t => t.SpChaoshiBindModule)
  },

  /** 所领导 / 审批超市解绑  */
  {
    path: 'sp-chaoshi-unbind',
    loadChildren: () => import('./sp-chaoshi-unbind/sp-chaoshi-unbind.module').then(t => t.SpChaoshiUnbindModule)
  },
  {
    path: 'sp-chaoshi-dingdan-suonei',
    loadChildren: () => import('./sp-chaoshi-dingdan-suonei/sp-chaoshi-dingdan-suonei.module').then(t => t.SpChaoshiDingdanSuoneiModule)
  },
  {
    path: 'jiashuSalesReturn',
    loadChildren: () => import('./sp-chaoshi-dingdan-salesReturn/jiashuSalesReturnModel').then(t => t.jiashuSalesReturnModule)
  },
  {
    path: 'snSalesReturn',
    loadChildren: () => import('./sp-chaoshi-dingdan-snSalesReturn/snSalesReturnModel').then(t => t.snSalesReturnModule)
  },
  {
    path: 'accraditation',
    loadChildren: () => import('./sp-chaoshi-dingdan-accraditation/accraditation').then(t => t.accraditationModule)
  },
  // 未出订单与已出订单查询台账
  {
    path: 'orderInfo',
    loadChildren: () => import('./sp-chaoshi-dingdan-orderInfo/orderInfoModel').then(t => t.orderInfoModel)
  },
  // 在押人出所代办事宜查询
  {
    path: 'commission',
    loadChildren: () => import('./sp-chaoshi-dingdan-commission/commissionModel').then(t => t.commissionModel)
  },
  {
    path: 'sp-chaoshi-dingdan-jiashu',
    loadChildren: () => import('./sp-chaoshi-dingdan-jiashu/sp-chaoshi-dingdan-jiashu.module').then(t => t.SpChaoshiDingdanJiashuModule)
  },
  {
    path: 'fh-chaoshi-dingdan-suonei',
    loadChildren: () => import('./fh-chaoshi-dingdan-suonei/fh-chaoshi-dingdan-suonei.module').then(t => t.FhChaoshiDingdanSuoneiModule)
  },
  {
    path: 'fh-chaoshi-dingdan-jiashu',
    loadChildren: () => import('./fh-chaoshi-dingdan-jiashu/fh-chaoshi-dingdan-jiashu.module').then(t => t.FhChaoshiDingdanJiashuModule)
  },
  {
    path: 'bl-daishouhuo',
    loadChildren: () => import('./bl-daishouhuo/bl-daishouhuo.module').then(t => t.BlDaishouhuoModule)
  },
  {
    path: 'bl-daigou',
    loadChildren: () => import('./bl-daigou/bl-daigou.module').then(t => t.BlDaigouModule)
  },
  {
    path: 'sp-daishouhuo',
    loadChildren: () => import('./sp-daishouhuo/sp-daishouhuo.module').then(t => t.SpDaishouhuoModule)
  },

  /** 审批 / 在呀人员购物 */
  {
    path: 'sp-zyry-gw',
    loadChildren: () => import('./sp-zyry-gw/sp-zyry-gw.module').then(m => m.SpZyryGwModule)
  },
  /** 审批 / 购物审批 */
  {
    path: 'sp-zyry-gw',
    loadChildren: () => import('./sp-zyry-gw/sp-zyry-gw.module').then(m => m.SpZyryGwModule)
  },
  {
    path: 'bl-xiane-shipin',
    loadChildren: () => import('./bl-xiane-shipin/bl-xiane-shipin.module').then(m => m.BlXianeShipinModule)
  },
  {
    path: 'bl-xiane-shyongpin',
    loadChildren: () => import('./bl-xiane-shyongpin/bl-xiane-shyongpin.module').then(m => m.BlXianeShyongpinModule)
  },

  /** 后勤 / 查询 / 所内购物订单 */
  {
    path: 'cx-hq-suonei-order',
    loadChildren: () => import('./cx-hq-suonei-order/cx-hq-suonei-order.module').then(m => m.CxHqSuoneiOrderModule)
  },
  {
    path: 'cx-hq-suonei-unapproved-order',
    loadChildren: () => import('./cx-hq-suonei-unapproved-order/cx-hq-suonei-order.module').then(m => m.CxHqSuoneiOrderUnapprovedModule)
  },
  {
    path: 'report-day',
    loadChildren: () => import('./report-day/report-day.module').then(m => m.ReportDayModule)
  },
  {
    path: 'report-month',
    loadChildren: () => import('./report-month/report-month.module').then(m => m.ReportMonthModule)
  },

  /** 后勤 / 代购回执 */
  {
    path: 'bl-hq-indent-receipt',
    loadChildren: () => import('./bl-hq-indent-receipt/bl-hq-indent-receipt.module').then(m => m.BlHqIndentReceiptModule)
  },

  /** 后勤 /所内发货 */
  {
    path: 'houqin-order-suonei-deliver',
    loadChildren: () => import('./houqin-order-suonei-deliver/houqin-order-suonei-deliver.module')
      .then(m => m.HouqinOrderSuoneiDeliverModule)
  },

  /** 管教 /所内收货 */
  {
    path: 'guanjiao-order-suonei-receive',
    loadChildren: () => import('./guanjiao-order-suonei-receive/guanjiao-order-suonei-receive.module')
      .then(m => m.GuanjiaoOrderSuoneiReceiveModule)
  },

  /** 管教 /家属购物收货 */
  {
    path: 'guanjiao-order-jiashu-receive',
    loadChildren: () => import('./guanjiao-order-jiashu-receive/guanjiao-order-jiashu-receive.module')
      .then(m => m.GuanjiaoOrderJiashuReceiveModule)
  },

  /** 财务 /所内购物审批 */
  {
    path: 'caiwu-order-suonei',
    loadChildren: () => import('./caiwu-order-suonei/caiwu-order-suonei.module').then(m => m.CaiwuOrderSuoneiModule)
  },

  /** 后勤 /退货管理 */
  {
    path: 'houqin-refund-order',
    loadChildren: () => import('./houqin-refund-order/houqin-refund-order.module').then(m => m.HouqinRefundOrderModule)
  },

  /** 所领导 /初始化日期金额 */
  {
    path: 'sp-date', loadChildren: () => import('./sp-date/sp-date.module').then(m => m.SpDateModule)
  },

  /** 管教 /留所订单管理 */
  {
    path: 'guanjiao-leave-confirm',
    loadChildren: () => import('./guanjiao-leave-confirm/guanjiao-leave-confirm.module').then(m => m.GuanjiaoLeaveConfirmModule)
  },

  /** 财务 /投牢确认 */
  {
    path: 'caiwu-toulao',
    loadChildren: () => import('./caiwu-toulao/caiwu-toulao.module').then(m => m.CaiwuToulaoModule)
  },

  /** 后勤 /申诉退款退货管理 */
  {
    path: 'houqin-complaint-refund',
    loadChildren: () => import('./houqin-complaint-refund/houqin-complaint-refund.module')
      .then(m => m.HouqinComplaintRefundModule)
  },

  /** 手工入账办理 */
  {
    path: 'bl-manual-entry',
    loadChildren: () => import('./bl-manual-entry/bl-manual-entry.module').then(m => m.BlManualEntryModule)
  },

  /** 手工出账办理 */
  {
    path: 'bl-manual-expenditure',
    loadChildren: () => import('./bl-manual-expenditure/bl-manual-expenditure.module').then(m => m.BlManualExpenditureModule)
  },

  /** 所领导 / 审批 / 手工入账 */
  {
    path: 'sp-manual-entry',
    loadChildren: () => import('./sp-manual-entry/sp-manual-entry.module').then(m => m.SpManualEntryModule)
  },

  /** 财务 / 确认 / 手工入账 */
  {
    path: 'cw-manual-entry',
    loadChildren: () => import('./cw-manual-entry/cw-manual-entry.module').then(m => m.CwManualEntryModule)
  },

  /** 后勤 / 手工退账办理 */
  {
    path: 'bl-manual-refund',
    loadChildren: () => import('./bl-manual-refund/bl-manual-refund.module').then(m => m.BlManualRefundModule)
  },

  /** 手工出账办理 */
  {
    path: 'bl-manual-accounting',
    loadChildren: () => import('./bl-manual-accounting/bl-manual-accounting.module').then(m => m.BlManualAccountingModule)
  },

  /** 所领导 / 审批 / 手工出账 */
  {
    path: 'sp-manual-accounting',
    loadChildren: () => import('./sp-manual-accounting/sp-manual-accounting.module').then(m => m.SpManualAccountingModule)
  },

  /** 财务 / 确认 / 手工出账 */
  {
    path: 'cw-manual-accounting',
    loadChildren: () => import('./cw-manual-accounting/cw-manual-accounting.module').then(m => m.CwManualAccountingModule)
  },

  /** 部分退货办理 */
  {
    path: 'bl-shopping-refund',
    loadChildren: () => import('./bl-shopping-refund/bl-shopping-refund.module').then(m => m.BlShoppingRefundModule)
  },

  // 部分退货审批
  {
    path: 'bl-shopping-audit',
    loadChildren: () => import('./bl-shopping-audit/bl-shopping-audit.module').then(m => m.BlShoppingAuditModule)
  },

  /** 申诉退款退货查询 */
  {
    path: 'cx-complaint-refund',
    loadChildren: () => import('./cx-complaint-refund/cx-complaint-refund.module').then(m => m.CxComplaintRefundModule)
  },

  /** 办理人工购物 */
  {
    path: 'bl-rengong-gouwu',
    loadChildren: () => import('./bl-rengong-gouwu/bl-rengong-gouwu.module').then(m => m.BlRengongGouwuModule)
  },

  /** 手工出账办理 打印 */
  {
    path: 'bl-manual-accounting-print',
    loadChildren: () => import(`./bl-manual-accounting-print/bl-manual-accounting-print.module`).then(m => m.BlManualAccountingPrintModule)
  },

  /** 手工入账办理 打印 */
  {
    path: 'bl-manual-entry-print',
    loadChildren: () => import('./bl-manual-entry-print/bl-manual-entry-print.module').then(m => m.BlManualEntryPrintModule)
  }
]
