import { Component, forwardRef, Injector, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { _HttpClient } from '@delon/theme'
import { SelectAsync } from '@public/components/select-async'
import { AccountModel, HttpAccount } from '@public/providers/http'
import { asyncCatch, deepGet, isResultFaild } from '@scaffold/methods'
import { uniqBy } from 'lodash'
import { NzSizeLDSType } from 'ng-zorro-antd/core/types'
import { InputBoolean } from 'ng-zorro-antd/core/util'

@Component({
  selector: 'app-ry-select-obj',
  templateUrl: './ry-select-obj.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RySelectObjComponent),
    multi: true
  }]
})
export class RySelectObjComponent extends SelectAsync<AccountModel, AccountModel> {

  @Input() @InputBoolean() showPrisonRoom = false
  @Input() params

  @Input() set prisonRoom(prisonRoom) {
    this._prisonRoom = prisonRoom
    this.clean()
    this.loadMore()
    this.value = null
  }

  @Input() testaaa
  @Input() testbbb

  get prisonRoom() { return this._prisonRoom }

  _prisonRoom: string

  @Input() nzSize: NzSizeLDSType = 'default'

  @Input() @InputBoolean() dataFlag = false

  @Input() prisonFlag: 0 | 1 | '' = 0

  constructor(
    injector: Injector,
    protected httpPrisoner: HttpAccount,
    protected http: _HttpClient
  ) { super(injector) }

  async updateValue(val: string | AccountModel) {
    // 如果传入的是id
    if (val !== '' && typeof val === 'string') {
      const _value: AccountModel = this.listOfOptions.find(ry => ry.id === val)
      val = _value || await this.getRyxxTotal(val)
      this._value = val
    }

    // 查无此人
    if (!val) {
      this._value = null
      return
    }

    const list = [val, ...this.listOfOptions]
    this.listOfOptions = uniqBy(list, (ry: AccountModel) => ry.id)
  }

  private async getRyxxTotal(id: string) {
    const promise = this.httpPrisoner.getById(id).toPromise()
    const [res] = await asyncCatch(promise)
    return (isResultFaild(res) || !res.response)
      ? null
      : res.response
  }

  private getParams() {
    const { prisonRoom } = this
    const params = this.prisonFlag === 1
      ? { prisonRoom, sort: 'out_time', order: 'desc', ...this.params }
      : { prisonRoom, ...this.params }
    return params
  }

  optionsFielter(ry: AccountModel) {
    return ry.id
  }

  handleFocus() {
    this.clean()
    this.loadMore()
    this.value = null

    // if (this.prisonFlag === '' && !this.firstLoading) {
    //   this.allPrisonList.length === 0 ? this.loadMore() : this.listOfOptions = this.allPrisonList
    // } else if (this.prisonFlag === 0 && !this.firstLoading) {
    //   this.zeroPrisonList.length === 0 ? this.loadMore() : this.listOfOptions = this.zeroPrisonList
    // } else if (this.prisonFlag === 1 && !this.firstLoading) {
    //   this.onePrisonList.length === 0 ? this.loadMore() : this.listOfOptions = this.onePrisonList
    // } else {
    //   if (!this.listOfOptions) {
    //     this.loadMore()
    //   }
    // }
  }

  async getOptions(text: string): Promise<AccountModel[]> {
    this.http.end()
    this.loading = true
    const params = this.getParams()

    if (this.testbbb && this.testbbb.length) {
      params.origin = this.testaaa.map(item => `${item.type}^${item.code}`).join('|')
      params.organ = this.testbbb
    }

    const promise = this.httpPrisoner.getAllByPage({ flag: this.prisonFlag, name: text, ...params, ...this.pagination }).toPromise()
    const [res] = await asyncCatch(promise)
    this.loading = false

    return isResultFaild(res)
      ? null
      : deepGet(res, 'response.records') || null
  }
}
