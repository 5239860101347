import { Component } from '@angular/core'
import { LayoutProComponent } from '@scaffold/layout'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent extends LayoutProComponent {
  get collapsed() {
    return this.pro.collapsed
  }
}
