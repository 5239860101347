import { Routes } from '@angular/router'

export const CHAOSHI_ROUTES: Routes = [
  { path: 'classification', redirectTo: '/m-shangping-fenlei' },
  {
    path: 'm-shangping-fenlei',
    loadChildren: () => import('./m-shangping-fenlei/m-shangping-fenlei.module').then(m => m.MShangpingFenleiModule)
  },

  /** 超市 / 超市订单 */
  { path: 'supermarket', redirectTo: '/m-chaoshi-dingdan' },
  {
    path: 'm-chaoshi-dingdan',
    loadChildren: () => import('./m-chaoshi-dingdan/m-chaoshi-dingdan.module').then(m => m.MChaoshiDingdanModule)
  },

  /** 超市 / 商品管理 */
  { path: 'shangpin', redirectTo: '/cs-shangpin' },
  {
    path: 'cs-shangpin',
    loadChildren: () => import('./cs-shangpin/cs-shangpin.module').then(m => m.CsShangpinModule)
  },

  /** 超市 / 商品管理 */
  { path: 'commodity', redirectTo: '/sp-shangping-shangjia' },
  {
    path: 'sp-shangping-shangjia',
    loadChildren: () => import('./sp-shangping-shangjia/sp-shangping-shangjia.module').then(m => m.SpShangpingShangjiaModule)
  },

  /** 超市 / 在押人员订单 */
  { path: 'distribution', redirectTo: '/m-zyry-dingdan' },
  {
    path: 'm-zyry-dingdan',
    loadChildren: () => import('./m-zyry-dingdan/m-zyry-dingdan.module').then(m => m.MZyryDingdanModule)
  },

]
