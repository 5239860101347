import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router'
import { ACLService } from '@delon/acl'

@Injectable({ providedIn: 'root' })
export class ACLGuard implements CanActivateChild {

  constructor(protected acl: ACLService, private router: Router) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { url } = state
    let flag
    switch (true) {
      case url.includes('/prisoner/detail'):
      case url.includes('/demo'):
      case url.includes('/403'):
      case url.includes('/404'):
      case url.includes('/500'):
      case url.includes('/exception/403'):
      case url.includes('/exception/404'):
      case url.includes('/exception/500'):
        flag = true
        break
      default:
        flag = this.acl.can(url)
        break
    }
    return flag
      ? flag
      : this.router.navigate([this.acl.guard_url], { queryParams: { url } })
  }
}
