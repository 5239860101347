import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { LayoutProMenuComponent } from '@scaffold/layout/components/menu/menu.component'

@Component({
  selector: 'app-layout-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less'],
  host: {
    '[class.jianguan__menu]': 'true',
    '[class.jianguan__menu-only-icon]': 'pro.onlyIcon',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutMenuComponent extends LayoutProMenuComponent {
  @Input() collapsed = true
  colors = [
    '#f62738',
    '#fb572f',
    '#2887fb',
    '#4c00a9',
    '#e9156b',
  ]

  getColor() {
    return this.colors[Math.floor(Math.random() * (this.colors.length))]
  }
}
