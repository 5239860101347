import { Component, Input, ViewChild } from '@angular/core'
import { SFComponent, SFSchema } from '@delon/form'
import { sfDefault } from '@public/methods/sf-util'

@Component({
  selector: 'app-prompt',
  template: `
    <sf #sf button="none" [schema]="schema"></sf>`
})
export class PromptComponent {

  @ViewChild('sf') sf: SFComponent

  @Input() schema: SFSchema = {
    properties: {
      text: { title: '填写内容', ...sfDefault() }
    }
  }

  get value() {
    return this.sf.value
  }

}
