import { SFDateWidgetSchema, SFSchema, SFSchemaType, SFTextareaWidgetSchema, SFUISchemaItem } from '@delon/form'
import { isValid } from 'date-fns'

export const widgets = {
  code: 'code-select',
  default: 'dm-select-obj',
  multiple: 'dm-select-arr'
}

export function toDate(str) {
  const date = new Date(str)
  return isValid(date) ? date : null
}

export function sfDefault(value = null, type: SFSchemaType = 'string'): SFSchema {
  return { type, default: value }
}

export function sfUiAsDefault() {
  return { onlyVisual: true }
}

export function sfUiAsDm(indexId, mode = 'default'): SFUISchemaItem {
  return {
    widget: widgets[mode],
    indexId,
    mode,
    onlyVisual: true
  }
}

export function sfUiAsdate(config: SFDateWidgetSchema = {}) {
  return {
    widget: 'date',
    format: 'yyyy/MM/dd HH:mm:ss',
    displayFormat: 'yyyy/MM/dd',
    ...config
  }
}

export function sfUiAsdateTime(config: SFDateWidgetSchema = {}) {
  return {
    widget: 'date',
    showTime: true,
    format: 'yyyy/MM/dd HH:mm:ss',
    displayFormat: 'yyyy/MM/dd HH:mm',
    ...config
  }
}

export function sfUiAsTextarea(config: SFTextareaWidgetSchema = {}) {
  return {
    widget: 'textarea',
    autosize: { minRows: 2, maxRows: 6 },
    ...config
  }
}
