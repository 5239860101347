import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { ExceptionComponent, ExceptionType, ReuseTabService } from '@delon/abc'
import { DelonLocaleService } from '@delon/theme'

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'exception',
  exportAs: 'exception',
  template: `
    <div class="exception__cont">
      <h1 class="exception__cont-title"
          [innerHTML]="_title"
          style="font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace"></h1>
      <div *ngIf="!hasCon" class="exception__cont-desc" [innerHTML]="_desc || locale[_type]"></div>
      <div class="exception__cont-actions">
        <div (cdkObserveContent)="checkContent()" #conTpl>
          <ng-content></ng-content>
        </div>
        <div class="mb-lg op-5 text-primary" *ngIf="text" style="margin-top: -10px;">{{text}}</div>

        <button nz-button (click)="close()" [nzType]="'primary'">关闭此页面</button>
      </div>
    </div>

    <div class="exception__img-block pr0">
      <div class="exception__img"
           [ngStyle]="{'background-image': 'url(' + _img + ')'}"></div>
    </div>
  `,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '[class.exception]': 'true',
    '[style.padding]': '"6vw"',
  },
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
// tslint:disable-next-line:component-class-suffix
export class Exception extends ExceptionComponent {

  @Input() text: string

  @Input()
  set type(value: ExceptionType) {
    const item = {
      403: {
        img: './assets/images/403.svg',
        title: '403',
      },
      404: {
        img: './assets/images/404.svg',
        title: '404',
      },
      500: {
        img: './assets/images/500.svg',
        title: '500',
      },
    }[value]

    if (!item) {
      return
    }

    this._type = value
    this._img = item.img
    this._title = item.title
  }

  constructor(private router: Router, private cdr: ChangeDetectorRef,
              private reuse: ReuseTabService,
              i18n: DelonLocaleService, dom: DomSanitizer
  ) {
    super(i18n, dom)

  }

  async close() {
    const { curUrl } = this.reuse
    this.reuse.close(curUrl)
  }
}
