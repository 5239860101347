import { Routes } from '@angular/router'

export const SYSTEM_ROUTES: Routes = [

  /** 系统设置 / 用户管理 */
  {
    path: 'user',
    loadChildren: () => import('./system-user/system-user.module').then(m => m.SystemUserModule)
  },

  /** 系统设置 / 角色管理 */
  {
    path: 'role',
    loadChildren: () => import('./system-role/system-role.module').then(m => m.SystemRoleModule)
  },

  /** 系统设置 / 菜单管理 */
  {
    path: 'menu',
    loadChildren: () => import('./system-menu/system-menu.module').then(m => m.SystemMenuModule)
  },

  /** 系统设置 / 字典管理 */
  {
    path: 'code',
    loadChildren: () => import('./system-code/system-code.module').then(m => m.SystemCodeModule)
  },

  /** 系统设置 / 状态管理 */
  {
    path: 'state',
    loadChildren: () => import('./system-state/system-state.module').then(m => m.SystemStateModule)
  },

  /** 系统设置 / 日期管理 */
  {
    path: 'date',
    loadChildren: () => import('./system-date/system-date.module').then(m => m.SystemDateModule)
  },

  /** 系统设置 / 开关管理 */
  {
    path: 'switch',
    loadChildren: () => import('./system-switch/system-switch.module').then(m => m.SystemSwitchModule)
  },

  /** 系统设置 / 生活食品 日期管理 */
  {
    path: 'system-date-life',
    loadChildren: () => import('./system-date-life/system-date-life.module').then(m => m.SystemDateLifeModule)
  },
]
