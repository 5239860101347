<!--主菜单图标-->
<ng-template #icon let-i>
  <ng-container *ngIf="i" [ngSwitch]="i.type">
    <i *ngSwitchCase="'icon'" nz-icon [nzType]="i.value || 'code-sandbox'"></i>
    <img *ngSwitchCase="'img'" src="{{ i.value }}" class="anticon jianguan__menu-img"/>
    <i *ngSwitchDefault class="anticon  {{ i.value }}"></i>
  </ng-container>
</ng-template>
<!--主菜单-->
<ng-template #mainLink let-i>
  <div class="jianguan__menu-app-icon jianguan__menu-app-icon-main" [style.color]="i.color || '#fd5c72'" [title]="i.text">
    <ng-template [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ $implicit: i.icon }"></ng-template>
  </div>
  <span class="jianguan__menu-app-text" *ngIf="!pro.onlyIcon">{{ i.text | translate}}</span>
  <div *ngIf="i.badge" title="{{ i.badge }}" class="jianguan__menu-title-badge badge">
    <em>{{ i.badge }}</em>
  </div>
</ng-template>
<!--子菜单-->
<ng-template #subLink let-i>
  <div class="jianguan__menu-app jianguan__menu-app-sub" routerLinkActive="jianguan__menu-app-active" [routerLink]="i.link" [title]="i.text">
    <div class="jianguan__menu-app-icon text-white" [style.background]="i.color || '#fd5c72'">
      <ng-template [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ $implicit: i.icon }"></ng-template>
    </div>
    <span class="jianguan__menu-app-text" *ngIf="pro.isMobile || !pro.onlyIcon">{{ i.text | translate}}</span>
    <div *ngIf="i.badge" title="{{ i.badge }}" class="jianguan__menu-title-badge badge">
      <em>{{ i.badge }}</em>
    </div>
  </div>
</ng-template>

<!--APP LIST-->
<ul *ngIf="menus" class="jianguan__menu text-white">
  <ng-container *ngFor="let l1 of menus">
    <li
      *ngIf="l1.children.length === 0"
      class="jianguan__menu-item jianguan__menu-item-li-1"
      [class.jianguan__menu-item--disabled]="l1.disabled"
    >
      <a
        *ngIf="!l1.externalLink"
        [routerLink]="l1.link"
        routerLinkActive="jianguan__menu-app-active"
        [target]="l1.target"
        (click)="closeCollapsed()"
        class="jianguan__menu-app"
      >
        <ng-template [ngTemplateOutlet]="mainLink" [ngTemplateOutletContext]="{ $implicit: l1 }"></ng-template>
      </a>
      <a
        *ngIf="l1.externalLink"
        [attr.href]="l1.externalLink"
        [attr.target]="l1.target"
        (click)="closeCollapsed()"
        class="jianguan__menu-app"
      >
        <ng-template [ngTemplateOutlet]="mainLink" [ngTemplateOutletContext]="{ $implicit: l1 }"></ng-template>
      </a>
    </li>
    <li
      *ngIf="l1.children.length > 0"
      class="jianguan__menu-item"
      [class.text-white]="pro.theme === 'dark' && l1._selected"
    >
      <a title class="jianguan__menu-app" nz-popover [nzPopoverTitle]="l1.text | translate" [nzPopoverContent]="submenuTpl"
           nzPopoverTrigger="hover"
           nzPopoverPlacement="rightTop">
        <div class="jianguan__menu-app-icon" [style.color]="l1.color || '#fd5c72'">
          <ng-template [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ $implicit: l1.icon }"></ng-template>
        </div>
        <span class="jianguan__menu-app-text" *ngIf="pro.isMobile || !pro.onlyIcon">{{ l1.text | translate}}</span>
        <div *ngIf="l1.badge" title="{{ l1.badge }}" class="jianguan__menu-title-badge badge">
          <em>{{ l1.badge }}</em>
        </div>
      </a>

      <ng-template #submenuTpl>
        <ul class="jianguan__menu-sub">
          <ng-container *ngFor="let l2 of l1.children">
            <li
              *ngIf="!l2._hidden && l2.children.length === 0"
              [class.jianguan__menu-item--disabled]="l2.disabled"
              (click)="closeCollapsed()"
            >
              <ng-template [ngTemplateOutlet]="subLink" [ngTemplateOutletContext]="{ $implicit: l2 }"></ng-template>
            </li>
            <li class="jianguan__menu-group" *ngIf="!l2._hidden && l2.children.length > 0">
              <nz-divider class="jianguan__menu-group-title" nzOrientation="left" [nzText]="l2.text | translate"></nz-divider>
              <ul class="jianguan__menu-sub">
                <ng-container *ngFor="let l3 of l2.children">
                  <li
                    *ngIf="!l3._hidden"
                    [class.jianguan__menu-item--disabled]="l3.disabled"
                    (click)="closeCollapsed()"
                  >
                    <ng-template [ngTemplateOutlet]="subLink"
                                 [ngTemplateOutletContext]="{ $implicit: l3 }"></ng-template>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ul>
      </ng-template>
    </li>
  </ng-container>
</ul>
