import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { LayoutMenuComponent } from './menu.component'

@NgModule({
  declarations: [LayoutMenuComponent],
  exports: [LayoutMenuComponent],
  imports: [
    CommonModule,
    NzIconModule,
    RouterModule,
    NzMenuModule,
    NzPopoverModule,
    NzDividerModule,
    TranslateModule,
  ],
})
export class YQMenuModule {}
