import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  {
    path: 'icon',
    loadChildren: () => import('./icon-list/icon-list.module').then(m => m.IconListModule),
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then(m => m.TestModule),
  },
  {
    path: 'debug',
    loadChildren: () => import('./debug/debug.module').then(m => m.DebugModule),
  },
  {
    path: 'debug-zyry-picker',
    loadChildren: () => import('./debug-zyry-picker/debug-zyry-picker.module').then(m => m.DebugZyryPickerModule),
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RouteRoutingModule {
}
