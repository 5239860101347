import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ACLService, DelonACLModule } from '@delon/acl'
import { _ACLService } from '@jianguan/shared/acl.service'
import { LayoutModule } from '@jianguan/shared/layout'
import { NgxNotificationMsgModule } from '@public/components/notification-msg'
import { SchemaModule } from '@public/json-schema'
import { PromptModule } from '@public/methods/prompt'
import { CoreModule } from '@scaffold/core'
import { AsyncMenu } from '@scaffold/core/menu/async-menu.service'
import { AsyncI18n } from '@scaffold/core/startup/async/async-i18n'
import { YqsEnvironmentsModule } from 'yqs-environments'

import { environment } from '../environments/environment'
import { PAGES_ROUTES } from '../routes/page-routes'
import { AppComponent } from './app.component'
import { AsyncI18nService } from './async-i18n.service'
import { AsyncMenuService } from './async-menu.service'

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    LayoutModule,
    YqsEnvironmentsModule.forRoot(environment),
    SchemaModule.forRoot(),
    DelonACLModule.forRoot(),
    NgxNotificationMsgModule,
    PromptModule,
    RouterModule.forRoot(PAGES_ROUTES, {
      useHash: environment.useHash,
      scrollPositionRestoration: 'disabled',
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: AsyncMenu, useClass: AsyncMenuService },
    { provide: AsyncI18n, useClass: AsyncI18nService },
    { provide: ACLService, useClass: _ACLService },
  ]
})
export class AppModule { }
