// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GLOBAL_ENVIRONMENT, IEnvironments } from '@environments'

export const environment: IEnvironments = {
  ...GLOBAL_ENVIRONMENT,
  FILE_URL: 'http://139.224.58.168:9008/capitalSupervisionSystem',
  SERVER_URL: 'http://139.224.58.168:9008/capitalSupervisionSystem',
  // FILE_URL: 'http://192.168.31.186:9009/capitalSupervisionSystem',
  // SERVER_URL: 'http://192.168.31.186:9009/capitalSupervisionSystem',
  production: false,
  useHash: true,
  APPID: 1,
  passportBackground: '#1743af',
  passportBanner: '/assets/images/jianguan.png',

  layoutConfig: {
    colorWeak: false,
    theme: 'dark',
    menu: 'side',
    contentWidth: 'fluid',
    fixedHeader: true,
    autoHideHeader: false,
    fixSiderbar: true,
    onlyIcon: false,
    fixed: true,
    collapsed: true,
    boxed: false,
    lang: null
  },
}
