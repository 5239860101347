import { Menu, MenuIcon } from '@delon/theme'

export const icon = (value: string,
                     theme: 'outline' | 'twotone' | 'fill' = 'fill',
                     type: 'class' | 'icon' | 'iconfont' | 'img' = 'icon',
): MenuIcon => ({ type, value, theme })

export const DEMO_MENUS: Menu[] = [
  {
    group: true,
    hideInBreadcrumb: true,
    children: [
      {
        text: '开发页面', group: true, icon: icon('solution', 'outline'), children: [
          { text: 'OA 蓝色图标', link: '/demo/icon/oa' },
          { text: '测试', link: '/demo/test' },
          { text: 'debug', link: '/demo/debug' },
          { text: '在押人员多选器', link: '/demo/debug-zyry-picker' },
        ],
      },
    ],
  },
]
